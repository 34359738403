import React, { FC } from "react";
import Sidebar from "./ds/layout/Sidebar";
import Button from "./ds/action/Button";
import styled from "styled-components";
import { IconClose, IconExternal } from "./ds/primitive/icons";
import ButtonIcon from "./ds/action/ButtonIcon";
import { H4 } from "./ds/primitive/typography";
import SocialMedia from "./SocialMedia";

interface Props {
    onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const MediaSidebar: FC<Props> = ({ onClose, ...props }) => (
    <StyledSidebar {...props}>
        <CloseButtonIcon
            onClick={onClose}
            icon={<IconClose />}
            title="Sluiten"
        />
        <SupportContainer>
            <H4>Steun Oerol</H4>
            <Button
                href="https://www.oerol.nl/nl/steun-oerol"
                target="_blank"
                label="Doneer"
                icon={<IconExternal />}
            />
            <Button
                href="https://www.oerol.nl/nl/vrienden-van-oerol"
                target="_blank"
                label="Vriend"
                icon={<IconExternal />}
            />
        </SupportContainer>
        <SocialMediaContainer>
            <H4>Volg oerol</H4>
            <SocialMedia />
        </SocialMediaContainer>
    </StyledSidebar>
);

const StyledSidebar = styled(Sidebar)`
    display: flex;

    & > * {
        width: 100%;
    }
`;

const CloseButtonIcon = styled(ButtonIcon)`
    position: absolute;
    top: 4rem;
    right: 4rem;
`;

const SupportContainer = styled.div`
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    margin: 0;

    & > * + * {
        margin-top: 1.6rem;
    }
`;

const SocialMediaContainer = styled.div`
    bottom: 0rem;
    position: absolute;
    margin: 0;

    & > * + * {
        margin-top: 0.8rem;
    }
`;

export default MediaSidebar;
