import React, { FC, ReactNode } from "react";
import Frame, { FrameProps } from "../layout/Frame";
import styled, { css } from "styled-components";
import { IconClose } from "../primitive/icons";
import ButtonIcon from "../action/ButtonIcon";
import { TitleBlock, InnerBlock } from "./BlockWithTitle";

export interface BlockTitleModalProps extends FrameProps {
    open: boolean;
    canClose?: boolean;
    onClickClose?: (e: any) => void;
    title: ReactNode;
}

const BlockTitleModal: FC<BlockTitleModalProps> = React.memo(({
    onClickClose,
    canClose = true,
    children,
    title,
    ...props
}) => (
    <StyledFrame tr {...props}>
        {canClose && (
            <CloseButton icon={<IconClose />} onClick={onClickClose} />
        )}
        <TitleBlock>{title}</TitleBlock>
        <InnerBlock>{children}</InnerBlock>
    </StyledFrame>
));

const modalClose = css`
    pointer-events: none;
    transform: scale(1, 0) translate3d(0, 0, 0);
    transition: 0.05s;
`;

const modalOpen = css`
    animation: turn-on 0.25s cubic-bezier(0.23, 1, 0.32, 1);
    animation-fill-mode: forwards;

    @keyframes turn-on {
        0% {
            transform: scale(0, 0.0001) translate3d(0, 0, 0);
            filter: brightness(50);
        }
        40% {
            transform: scale(1.3, 0.001) translate3d(0, 0, 0);
            filter: brightness(10);
        }
        100% {
            transform: scale(1, 1) translate3d(0, 0, 0);
            filter: brightness(1);
            opacity: 1;
        }
    }
`;

const StyledFrame = styled(Frame)<{ open: boolean }>`
    position: relative;
    width: 32.5rem;
    ${({ open }) => (open ? modalOpen : modalClose)}
`;

const CloseButton = styled(ButtonIcon)`
    position: absolute;
    bottom: 100%;
    left: 100%;
    z-index: 1;

    @media (max-width: 580px) {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
    }
`;

export default BlockTitleModal;
