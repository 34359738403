import styled from "styled-components";

export default styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    & > main {
        height: 100%;
        box-sizing: border-box;
        padding-right: 19.2rem;
    }

    & > aside {
        --padding: 2rem;
        --width: 19.2rem;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        width: var(--width);
    }

    @media (max-width: 580px) {
        display: flex;
        flex-direction: column;

        & > main {
            position: relative;
            padding: 8px;
            flex: 1;
            height: auto;
        }

        & > aside {
            position: relative;
            height: auto;
            padding: 8px;
            display: flex;
            align-items: flex-end;
            z-index: 2;
            width: auto;
            border-bottom: 1px solid rgba(255,255,255,0.4);
        }
    }
`;
