import React, { FC } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

export interface MediaProps { 
    url: string
}

const Media: FC<MediaProps> = ({ url, ...props }) => (
    <Container {...props}>
        <ReactPlayer
            url={url}
            playing
            controls
            width="100%"
            height="100%"
            config={{
                soundcloud: {
                    options: {
                        hide_related: true
                    } 
                }
            }}
        />
    </Container>
);

const percFillMobile = 96;

const Container = styled.figure`
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100vh;

    @media (max-width: 580px) {
        width: ${percFillMobile}vw;
        height: ${(9 / 16) * percFillMobile}vw; /* height:width ratio = 9/16 = .5625  */
        max-height: ${percFillMobile}vh;
        max-width: ${(16 / 9) * percFillMobile}vh; /* 16/9 = 1.778 */
    }
`;
export default Media;
