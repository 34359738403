import React, { FC, useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';

import LayoutDetails from './ds/layout/LayoutDetails';
import Media from './ds/content/Media';
import BlockTitleModal from './ds/block/BlockTitleModal';
import Content from './ds/content/Content';
import { H2, CMSContent } from './ds/primitive/typography';
import MediaSidebar from './MediaSidebar';
import {
    Craft_EventsEvent,
    Craft_EventsHost,
    Craft_ProgrammingTime,
    SitePageContextDataEventImageFilterInput,
} from '../graphql';
import BlockClock from './ds/block/BlockClock';
import Button from './ds/action/Button';
import { IconExternal, IconClose, IconPlus } from './ds/primitive/icons';
import cssCalcBetweenViewport from '../utils/cssCalcBetweenViewport';
import { useIsMobile } from '../utils/useIsMobile';
import ButtonIcon from './ds/action/ButtonIcon';
import { navigate } from 'gatsby';
import BlockModal from './ds/block/BlockModal';
import { TitleBlock, InnerBlock } from './ds/block/BlockWithTitle';
import dateFromSeconds from '../utils/dateFromSeconds';
import { DateTime } from 'luxon';
import nowBetween from '../utils/nowBetween';
import Helmet from 'react-helmet';
import isBrowser from '../utils/isBrowser';
import { EVENT_START_DATE, getDayLabel } from '../services/useDayNavigation';
import AuthContext from '../contexts/AuthContext';
import userHasAccess from '../services/userHasAccess';
import TicketOptions from './TicketOptions';
import Background from './ds/layout/Background';

interface Props {
    pageContext: {
        data: {
            event: Craft_EventsEvent | Craft_EventsHost;
            playDate: string;
        };
    };
}

const PageShow: FC<Props> = ({
    pageContext: {
        data: { event, playDate },
    },
}) => {
    const { auth } = useContext(AuthContext);

    const hasAccess = useMemo(() => event.__typename === 'Craft_EventsEvent' && userHasAccess(auth, event), [
        auth,
        event,
    ]);

    const programmingTime: Craft_ProgrammingTime | null | undefined =
        event.__typename === 'Craft_EventsEvent' ? event.programmingTime?.[0] && event.programmingTime[0] : undefined;
    const isOnDemand = programmingTime?.isOnDemand;

    const eventDate = dateFromSeconds(
        event?.__typename === 'Craft_EventsEvent'
            ? isOnDemand && programmingTime?.validFrom
                ? programmingTime.validFrom
                : playDate
            : playDate
    );
    const eventStart =
        event?.__typename === 'Craft_EventsEvent'
            ? (isOnDemand ? programmingTime?.validFrom : event.eventStart) &&
              dateFromSeconds(isOnDemand ? programmingTime?.validFrom : event.eventStart)
            : undefined;
    const eventEnd =
        event?.__typename === 'Craft_EventsEvent'
            ? (isOnDemand ? programmingTime?.validUntil : event.eventEnd) &&
              dateFromSeconds(isOnDemand ? programmingTime?.validUntil : event.eventEnd)
            : undefined;
    const [today, setToday] = useState<DateTime>();
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(true);
    const [infoVisible, setInfoVisible] = useState(false);
    const background = String((event.image as SitePageContextDataEventImageFilterInput[])?.[0]?.background);

    useEffect(() => {
        setToday(DateTime.fromObject({ zone: 'Europe/Amsterdam' }));

        const interval = setInterval(() => {
            setToday(DateTime.fromObject({ zone: 'Europe/Amsterdam' }));
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const eventInFuture = today && today?.ordinal > eventDate.ordinal;
    const eventToday = today && today?.ordinal === eventDate.ordinal;
    const eventNowVisible = eventInFuture || (eventToday && (!eventStart || nowBetween(eventStart, eventEnd)));

    const onClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isBrowser && window?.history?.state?.prevUrl) {
            history.back();
        } else {
            navigate('/');
        }
    };

    return (
        <LayoutDetails>
            <Helmet>
                <title>{event.blockTitle} | Het Imaginaire Eiland / Imagine Your Island | Oerol</title>
                <meta
                    property="og:title"
                    content={`${
                        event.blockTitle ? event.blockTitle + ' | ' : ''
                    }Het Imaginaire Eiland / Imagine Your Island | Oerol`}
                />
                {event.__typename === 'Craft_EventsEvent' && event.description?.content && (
                    <meta property="og:description" content={event.description.content.replace(/(<([^>]+)>)/gi, '')} />
                )}
            </Helmet>
            {background && <Background imgSrc={[background]} />}
            {!isMobile ? (
                <>
                    <main>
                        <PositionedBlockClock
                            showNavigation={false}
                            date={EVENT_START_DATE}
                            time={getDayLabel(EVENT_START_DATE)}
                        />

                        <Centered>
                            {hasAccess || event.__typename === 'Craft_EventsHost' ? (
                                eventNowVisible ? (
                                    event.media &&
                                    (event.mediaType === 'embedded' ? (
                                        <Media url={event.media} />
                                    ) : (
                                        <Button
                                            label="START"
                                            href={event.media}
                                            target="_blank"
                                            icon={<IconExternal />}
                                        />
                                    ))
                                ) : (
                                    <H2>
                                        {eventStart && eventDate
                                            ? `
                                                    Vanaf
                                                    ${eventStart
                                                        ?.setLocale('nl-NL')
                                                        .toLocaleString(
                                                            DateTime.TIME_SIMPLE
                                                        )} op ${eventDate?.setLocale('nl-NL').toFormat('dd LLLL')}
                                                    gaan de digitale deuren open.
                                                `
                                            : `De digitale deuren zijn nog niet open.`}
                                    </H2>
                                )
                            ) : (
                                <div>
                                    <H2>Voor dit evenement heb je toegang nodig.</H2>
                                    <br />
                                    {event.__typename === 'Craft_EventsEvent' && (
                                        <Button
                                            label={<TicketOptions event={event} />}
                                            link={<TicketOptions event={event} />}
                                        />
                                    )}
                                </div>
                            )}
                        </Centered>
                        {event.__typename === 'Craft_EventsEvent' && event.description?.content && (
                            <ShowDescriptionBlockWithTitle
                                open={open}
                                background="pattern"
                                title={<H2>INFO</H2>}
                                onClickClose={() => {
                                    setOpen(!open);
                                }}
                            >
                                <ScrollContent>
                                    <CMSContent
                                        dangerouslySetInnerHTML={{
                                            __html: event.description.content,
                                        }}
                                    />
                                </ScrollContent>
                            </ShowDescriptionBlockWithTitle>
                        )}
                    </main>
                    <MediaSidebar onClose={onClose} />
                </>
            ) : (
                <>
                    <aside>
                        <PositionedBlockClock
                            showNavigation={false}
                            date={EVENT_START_DATE}
                            time={getDayLabel(EVENT_START_DATE)}
                        />
                        {isMobile && (
                            <MobileToggles>
                                <Button
                                    href="https://www.oerol.nl/nl/steun-oerol"
                                    target="_blank"
                                    label="Doneer"
                                    icon={<IconExternal />}
                                />
                                <Button
                                    href="https://www.oerol.nl/nl/vrienden-van-oerol"
                                    target="_blank"
                                    label="Word Vriend"
                                    icon={<IconExternal />}
                                />
                            </MobileToggles>
                        )}
                        <CloseButtonIcon onClick={onClose} icon={<IconClose />} title="Sluiten" />
                        {event.__typename === 'Craft_EventsEvent' && event.description?.content && (
                            <InfoBlockModal
                                open={infoVisible}
                                background="pattern"
                                canClose
                                onClickClose={() => setInfoVisible(false)}
                            >
                                <TitleBlock>
                                    <H2>INFO</H2>
                                </TitleBlock>
                                <InnerBlock>
                                    <Content>
                                        <CMSContent
                                            dangerouslySetInnerHTML={{
                                                __html: event.description.content,
                                            }}
                                        />
                                    </Content>
                                </InnerBlock>
                            </InfoBlockModal>
                        )}
                    </aside>
                    <StyledMain faded={infoVisible}>
                        <Centered>
                            <MediaContainer>
                                {hasAccess ? (
                                    eventNowVisible ? (
                                        event.media &&
                                        (event.mediaType === 'embedded' ? (
                                            <Media url={event.media} />
                                        ) : (
                                            <Button
                                                label="Bekijk media"
                                                href={event.media}
                                                target="_blank"
                                                icon={<IconExternal />}
                                            />
                                        ))
                                    ) : (
                                        <>
                                            <H2>
                                                {`
                                                Vanaf 
                                                ${eventStart
                                                    ?.setLocale('nl-NL')
                                                    .toLocaleString(DateTime.TIME_SIMPLE)} op ${eventDate
                                                    ?.setLocale('nl-NL')
                                                    .toFormat('dd LLLL')}
                                                gaan de digitale deuren open.
                                            `}
                                            </H2>
                                        </>
                                    )
                                ) : (
                                    <div>
                                        <H2>Voor dit evenement heb je toegang nodig.</H2>
                                        <br />
                                        {event.__typename === 'Craft_EventsEvent' && (
                                            <Button
                                                label={<TicketOptions event={event} />}
                                                link={<TicketOptions event={event} />}
                                            />
                                        )}
                                    </div>
                                )}
                                {event.__typename === 'Craft_EventsEvent' && event.description?.content && (
                                    <Button
                                        label="Meer informatie"
                                        onClick={() => {
                                            setInfoVisible(true);
                                        }}
                                        icon={<IconPlus />}
                                    />
                                )}
                            </MediaContainer>
                        </Centered>
                    </StyledMain>
                </>
            )}
        </LayoutDetails>
    );
};

const PositionedBlockClock = styled(BlockClock)`
    position: absolute;
    top: ${cssCalcBetweenViewport(24, 64)};
    left: ${cssCalcBetweenViewport(24, 64)};
    z-index: 2;
    pointer-events: none;

    @media (max-width: 580px) {
        position: static;
    }
`;

const ShowDescriptionBlockWithTitle = styled(BlockTitleModal)`
    position: absolute;
    top: ${cssCalcBetweenViewport(184, 264)};
    left: ${cssCalcBetweenViewport(24, 64)};
    width: ${cssCalcBetweenViewport(240, 420)};
`;

const ScrollContent = styled(Content)`
    max-height: 50vh;
    overflow: auto;
`;

const Centered = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

// Mobile stuff

const MobileToggles = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.6rem;

    & > * + * {
        margin-top: 0.4rem;
    }
`;

const CloseButtonIcon = styled(ButtonIcon)`
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
`;

const InfoBlockModal = styled(BlockModal)`
    position: absolute;
    top: calc(100% + 1.6rem);
    left: 0.8rem;
    bottom: 0.8rem;
    width: calc(100vw - 1.6rem);
    z-index: 10;
    margin-left: 0;
    height: calc(100vh - 16rem - 2.4rem);

    & ${InnerBlock} {
        height: calc(100% - 4rem);
        box-sizing: border-box;
        overflow-y: auto;
    }
`;

const MediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
        padding-top: 3.2rem;
    }
`;

const StyledMain = styled.main<{ faded: boolean }>`
    will-change: transform;
    ${({ faded }) =>
        faded &&
        `
        opacity: 0;
        pointer-events: none;
    `}
`;

export default PageShow;
